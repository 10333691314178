<template>
  <div :class="['cmp-wrapper', isDark && 'cmp-wrapper--dark']">
    <navbar msg="بونوس" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading'"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <section class="load-resolved" v-else-if="pageStatus == 'resolved'">
      <bonus-single
        :price="bonus['price']"
        :min-buy="bonus['min_buy']"
        :is-dark="isDark"
        bonus-inner
      />
      <i class="desc__hr" />
      <div :class="['desc', isDark && 'desc--dark']">
        <h4 class="desc__title">توضیحات</h4>
        <p class="desc__text" v-text="bonus['desc']" />
      </div>
      <section :class="['time-wrapper', isDark && 'time--dark']">
        <p v-if="diffDay > 1">
          از
          <span>{{ localizeDate(bonus['created_at']) }}</span>
          تا
          <span v-text="diffDay - 1" />
          روز
        </p>
        <p v-else>
          <span>{{ localizeDate(bonus['created_at']) }}</span>
        </p>
        <count-down
          v-if="bonus"
          :endDate="applyTimeZone(bonus['expire_at'])"
          :permission="!isDark"
          class="countdown"
        />
        <div class="offBtn" v-if="!isDark" @click="modals['confirm'] = true">
          <svg
            class="offBtn__svg"
            width="512px"
            height="512px"
            viewBox="0 0 512 512"
            v-show="!btnLoading"
          >
            <g id="Layer_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <g id="_1446895942480">
                <path
                  d="M311.36 76.79c-7.29,-2.13 -14.94,2.05 -17.07,9.34 -2.14,7.29 2.05,14.94 9.34,17.07 69.94,20.47 121.59,85.18 121.59,162.43 0,93.31 -75.91,169.22 -169.22,169.22 -93.31,0 -169.22,-75.91 -169.22,-169.22 0,-77.09 51.49,-141.91 121.59,-162.43 7.29,-2.13 11.47,-9.78 9.34,-17.07 -2.14,-7.29 -9.78,-11.47 -17.07,-9.34 -80.65,23.61 -141.38,98.33 -141.38,188.84 0,108.74 87.99,196.74 196.74,196.74 108.74,0 196.74,-87.99 196.74,-196.74 0,-89.92 -60.15,-165.06 -141.38,-188.84z"
                />
                <path
                  d="M256 49.63c-7.6,0 -13.76,6.16 -13.76,13.76l0 129.83c0,7.6 6.16,13.76 13.76,13.76 7.6,0 13.76,-6.16 13.76,-13.76l0 -129.83c0,-7.6 -6.16,-13.76 -13.76,-13.76z"
                />
              </g>
            </g>
          </svg>
        </div>
        <div class="offBtn offBtn--dark" v-if="isDark">
          <svg
            class="offBtn__svg"
            width="512px"
            height="512px"
            viewBox="0 0 512 512"
          >
            <path
              d="M311.36 76.79c-7.29,-2.13 -14.94,2.05 -17.07,9.34 -2.14,7.29 2.05,14.94 9.34,17.07 69.94,20.47 121.59,85.18 121.59,162.43 0,93.31 -75.91,169.22 -169.22,169.22 -93.31,0 -169.22,-75.91 -169.22,-169.22 0,-77.09 51.49,-141.91 121.59,-162.43 7.29,-2.13 11.47,-9.78 9.34,-17.07 -2.14,-7.29 -9.78,-11.47 -17.07,-9.34 -80.65,23.61 -141.38,98.33 -141.38,188.84 0,108.74 87.99,196.74 196.74,196.74 108.74,0 196.74,-87.99 196.74,-196.74 0,-89.92 -60.15,-165.06 -141.38,-188.84z"
            />
            <path
              d="M256 49.63c-7.6,0 -13.76,6.16 -13.76,13.76l0 129.83c0,7.6 6.16,13.76 13.76,13.76 7.6,0 13.76,-6.16 13.76,-13.76l0 -129.83c0,-7.6 -6.16,-13.76 -13.76,-13.76z"
            />
          </svg>
          <span class="offBtn__text">
            {{ localizeDate(bonus['cancelled_at']) }}
          </span>
        </div>
      </section>
      <div class="tab" :class="['tab', isDark && 'tab--dark']">
        <div v-if="!isDark" class="tab__part" @click="enterBonusItems(1)">
          <h6 class="tab__count" v-text="bonusItemsCount['1']" />
          <span class="tab__status">بونوس فعال</span>
        </div>
        <div @click="enterBonusItems(2)" class="tab__part">
          <h6 class="tab__count" v-text="bonusItemsCount['2']" />
          <span class="tab__status">بونوس مصرف شده</span>
        </div>
        <div v-if="isDark" @click="enterBonusItems(3)" class="tab__part">
          <h6 class="tab__count" v-text="bonusItemsCount['3']" />
          <span class="tab__status">بونوس باطل شده</span>
        </div>
      </div>
      <div class="filters-wrapper" v-if="bonusType">
        <filters-table
          v-if="bonusType === 'typical'"
          :filters="bonus.filters"
          :isDark="isDark"
        />
        <div class="single-item" v-else>
          این بونوس به صورت تکی ایجاد شده است.
        </div>
      </div>
    </section>
    <failed-mode @refresh="getBonus" v-else />
    <modal-confirm
      :is-open="modals['confirm']"
      :is-loading="btnLoading"
      @modalconfirmclosed="modals['confirm'] = false"
      @modalconfirmcancelled="modals['confirm'] = false"
      @confirm="handleDisableBonus"
      text='آیا از "ابطال" این بونوس مطمئن هستید؟'
    />
    <fixed-footer />
  </div>
</template>

<script>
import { computed, onBeforeUnmount, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { disableBonus } from '@service/BonusService'
import CountDown from '@common/CountDown'
import FiltersTable from '@common/FiltersTable'
import BonusSingle from './subComponents/BonusSingle'
import dateConvertor from '@composable/dateConvertor'
export default {
  name: 'BonusShow',
  components: {
    CountDown,
    FiltersTable,
    BonusSingle
  },
  props: ['id'],
  setup(props) {
    // Fetch bonus
    const store = useStore()
    const pageStatus = computed(() => store.state.bonus.pageStatus)
    const isDark = computed(() => store.state.bonus.isDark)
    const bonus = computed(() => store.state.bonus.item)
    const bonusType = computed(() => {
      if (!bonus.value) return null
      return bonus.value['filters']['creation_type'] === '1'
        ? 'typical'
        : 'single'
    })

    // Initial API call
    const getBonus = () => {
      store.dispatch('bonus/fetchItem', { part: 'bonus', id: props.id })
      store.dispatch('bonus/fetchBonusItemsCount', props.id)
    }
    getBonus()

    // Disable bonus
    const btnLoading = ref(false)
    const handleDisableBonus = async () => {
      if (btnLoading.value) return

      btnLoading.value = true
      try {
        await disableBonus(props.id)
        getBonus()
        store.dispatch('addToast', {
          type: 'success',
          message: 'بونوس با موفقیت ابطال گردید.'
        })
        setTimeout(() => router.push({ name: 'BonusHome' }), 2000)
      } catch (ex) {
        if (ex.response) {
          store.dispatch('addToast', {
            type: 'error',
            message: 'درخواست شما موفقیت‌آمیز نبود.'
          })
        }
      } finally {
        btnLoading.value = false
        modals['confirm'] = false
      }
    }

    // Necessary items
    const modals = reactive({
      confirm: false
    })

    const { getRemainingDay, localizeDate, applyTimeZone } = dateConvertor()
    const diffDay = computed(() => {
      return getRemainingDay(
        bonus.value['created_at'],
        bonus.value['expire_at']
      )
    })
    // Enter bonusItems
    const bonusItemsCount = computed(() => store.state.bonus.bonusItemsCount)
    const router = useRouter()
    const enterBonusItems = (status) => {
      if (bonusItemsCount.value[status] === 0) {
        return store.dispatch('addToast', {
          type: 'error',
          message: 'داده‌ای برای نمایش موجود نیست.'
        })
      }
      router.push({ name: 'BonusStatus', params: { status, id: props.id } })
    }

    // Clean the room
    onBeforeUnmount(() => {
      store.dispatch('bonus/clearItem')
    })

    return {
      bonus,
      enterBonusItems,
      isDark,
      handleDisableBonus,
      bonusItemsCount,
      btnLoading,
      modals,
      bonusType,
      localizeDate,
      diffDay,
      applyTimeZone,
      pageStatus,
      getBonus
    }
  }
}
</script>

<style lang="scss" scoped>
$dark-text-color: #444;
$dark-bg-color-primary: #080808;
$dark-bg-color-secondary: #181818;
.desc {
  width: 100%;
  padding: 0 25px;
  margin: 15px 0 25px;
}
.desc__title {
  color: #000;
  width: 100%;
  margin: 10px 0 5px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}
.desc__text {
  color: #686868;
  width: 100%;
  margin: 5px 0 0;
  text-align: justify;
  text-align-last: center;
  font-size: 12px;
  padding: 0 3px;
}
.desc--dark .desc__title {
  color: #777;
  font-weight: normal;
}
.desc__hr {
  width: 94%;
  height: 1px;
  background-color: rgba(#000, 0.1);
}

// change in common css
.cmp-wrapper {
  padding-bottom: 75px;
  background: linear-gradient(to bottom, #fff 0, #fff 150px, #eee 220px);
}
.countdown {
  margin-top: 0;
  padding-top: 10px;
  width: 100%;
}
.tab {
  margin-bottom: 18px;
}
.tab--dark {
  background-color: #080808;
}
.load-pending--show {
  height: calc(100vh - 135px);
}

.cmp-wrapper--dark {
  background: $dark-bg-color-primary;
}
.cmp-wrapper--dark .bonus-top {
  background: rgba(#181818, 0.5);
}
.cmp-wrapper--dark .bonus-top__icon {
  background-color: rgba(#888, 0.4);
  fill: #080808;
}
.cmp-wrapper--dark .bonus-top__price {
  color: #888;
  &::after {
    color: #888;
  }
}
.cmp-wrapper--dark .bonus-top__title {
  color: rgba(#888, 0.4);
}
</style>
