<template>
  <div class="charge-wrapper">
    <template v-if="bonusType === 'single'">
      <div class="filtered-customers filtered-customers--single">
        <span class="count" v-text="bonusOptions?.name" />
        <h3 class="title">آماده دریافت بونوس</h3>
      </div>
    </template>
    <template v-else>
      <div class="filtered-customers">
        <span
          class="count"
          v-text="
            bonusOptions ? bonusOptions?.customersCount?.toLocaleString() : 0
          "
        />
        <h3 class="title">نفر آماده دریافت بونوس</h3>
      </div>
    </template>

    <form novalidate class="form-wrapper" @submit.prevent="submitForm">
      <base-input-number
        label="مبلغ بونوس"
        rial
        star
        v-model="formItems['bonusPrice']"
      />
      <base-input-number
        label="حداقل خرید"
        v-model="formItems['bonusMin']"
        rial
        star
        style="margin-top: 15px;"
      />
      <div class="bta-wrapper">
        <box-textarea
          label="توضیحات"
          width="100%"
          animateType="remove"
          v-model="formItems['bonusDesc']"
          starLabel
        />
      </div>
      <div class="credit-buttons">
        <a class="button button--form" @click="modals['day'] = true">
          <span :class="['button__text', !!formItems['bonusTime'] && 'active']">
            {{ btnDay }}
          </span>
          <span
            class="button__text active--detail"
            v-show="formItems['bonusTime'] > 1"
            >روز</span
          >
        </a>
        <!-- <div class="bonus__online">
          <h4>
            پرداخت آنلاین
          </h4>
          <toggle-switch v-model="bonusOnline" />
        </div> -->
        <!-- <div class="table table--form">
          <div class="table__row">
            <h6 class="table__cell cell--title">
              تعداد مشتری
            </h6>
            <h6 class="table__cell cell--amount">
              {{ customersCount.toLocaleString() }}
            </h6>
          </div>
          <i class="table__hr" />
          <div class="table__row">
            <h6 class="table__cell cell--title">
              ارسال هر بونوس
            </h6>
            <h6 class="table__cell cell--price ">
              {{ bonusTablePrice.toLocaleString() }}
            </h6>
          </div>
          <i class="table__hr" />
          <div class="table__row">
            <h6 class="table__cell cell--title">
              هزینه کل
            </h6>
            <h6 class="table__cell cell--price ">
              {{ finalPrice.toLocaleString() }}
            </h6>
          </div>
        </div> -->
        <button type="submit" class="button button--black">
          <span class="button__text">ایجاد</span>
          <spinner v-if="btnLoading" />
        </button>
      </div>
    </form>
    <modal
      :is-open="modals['day']"
      @modalClosed="modals['day'] = false"
      title="تاریخ اعتبار"
    >
      <ul class="items-wrapper">
        <li v-for="(day, idx) in days" :key="day">
          <a
            :class="formItems['bonusTime'] === day + 1 && 'active'"
            @click="
              () => {
                if (idx === 0) formItems['bonusTime'] = 1
                else formItems['bonusTime'] = day + 1
                modals['day'] = false
              }
            "
          >
            {{ day }} <span v-if="idx > 0">روز</span>
          </a>
        </li>
      </ul>
    </modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  schemaMaker,
  reqString,
  price,
  modalNumber,
  validateForms
} from '@valid/JoiValidation'
import { dateMixin } from '@mixin/dateMixin'
import { addBonus, addSingleBonus } from '@service/BonusService'
import BoxTextarea from '@common/BoxTextarea'
import BaseInputNumber from '@common/BaseInputNumber'
// import ToggleSwitch from '@common/ToggleSwitch'

const isFormValid = schemaMaker({
  price: price('مبلغ بونوس', 0),
  priceMin: price('حداقل خرید', 0),
  desc: reqString('توضیحات'),
  time: modalNumber('تاریخ اعتبار', 1)
})

export default {
  name: 'BonusNew',
  mixins: [dateMixin],
  components: {
    BoxTextarea,
    BaseInputNumber
    // ToggleSwitch
  },
  data() {
    return {
      modals: {
        day: false
      },
      formItems: {
        bonusPrice: '',
        bonusMin: '',
        bonusDesc: '',
        bonusTime: null
      },
      selectedDay: null,
      // Array of numbers for days
      days: ['امروز', ...Array.from({ length: 100 }, (_, i) => i + 1)],
      btnLoading: false,
      isDone: false,
      bonusTablePrice: 500,
      bonusOnline: false
    }
  },
  methods: {
    async submitForm() {
      if (this.btnLoading || this.isDone) return

      const {
        bonusPrice: price,
        bonusMin: priceMin,
        bonusDesc: desc,
        bonusTime: time
      } = this.formItems

      const errors = validateForms({ price, priceMin, desc, time }, isFormValid)

      if (errors && errors.length !== 0) {
        // # add a alert box to show this message

        for (let key of errors) {
          const message = { type: 'error', message: key }
          this.$store.dispatch('addToast', message)
        }
        return
      }

      this.btnLoading = true

      // init finalForm
      const form = {}
      form['price'] = price
      form['min_buy'] = priceMin
      form['desc'] = desc
      form['expire_at'] = this.generateExpireTime(time)

      const finalForm = { ...this.filters, ...form }

      if (this.bonusType === 'normal') {
        try {
          await addBonus(finalForm)
          this.isDone = true
          this.btnLoading = false
          this.$store.dispatch('addToast', {
            type: 'success',
            message: 'بونوس با موفقیت ایجاد گردید.'
          })

          this.$store.dispatch('fetchHomeData')
          setTimeout(() => this.$router.push({ name: 'BonusHome' }), 2000)
        } catch (ex) {
          switch (ex.response.status) {
            case 402:
              this.$store.dispatch('profile/handleCreditModal', {
                status: 'open',
                type: 'credit'
              })
              break
            default:
              this.addToast({
                type: 'error',
                message: 'درخواست شما موفقیت‌آمیز نبود.'
              })
          }
        } finally {
          this.btnLoading = false
        }
      } else if (this.bonusType === 'single') {
        try {
          form['wallet_id'] = this.bonusOptions?.customerId
          await addSingleBonus(form)
          this.isDone = true
          this.btnLoading = false
          this.$store.dispatch('addToast', {
            type: 'success',
            message: 'بونوس با موفقیت ایجاد گردید.'
          })

          this.$store.dispatch('fetchHomeData')
          setTimeout(() => this.$router.push({ name: 'BonusHome' }), 1500)
        } catch (ex) {
          switch (ex.response.status) {
            case 402:
              this.$store.dispatch('profile/handleCreditModal', {
                status: 'open',
                type: 'credit'
              })
              break
            default:
              this.addToast({
                type: 'error',
                message: 'درخواست شما موفقیت‌آمیز نبود.'
              })
          }
        } finally {
          this.btnLoading = false
        }
      }
    }
  },
  computed: {
    ...mapState({
      filters: (state) => state.customer.filterData,
      bonusOptions: (state) => state.bonus.bonusOptions
    }),
    bonusType() {
      return this.bonusOptions?.bonusType
    },
    customersCount() {
      if (this.bonusType == 'single') {
        return 1
      } else if (this.bonusType == 'normal') {
        return this.bonusOptions.customersCount
      } else return 0
    },
    finalPrice() {
      return this.bonusTablePrice * this.customersCount
    },
    btnDay() {
      const day = this.formItems['bonusTime']
      if (day) {
        return day === 1 ? 'امروز' : day - 1
      } else return 'تاریخ اعتبار'
    }
  },
  created() {
    // Make sure if user refresh the page he/she must fill the filter form again
    if (!this.bonusOptions)
      this.$router.push({
        name: 'BonusHome'
      })
  },
  watch: {
    bonusOnline(curr) {
      if (curr) {
        this.$store.dispatch('addToast', {
          type: 'error',
          message: 'پرداخت آنلاین برای شما فعال نمی‌باشد.'
        })
        setTimeout(() => (this.bonusOnline = false), 500)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.charge-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(to bottom, #eee 0, #fff 40px);
}

.plan-cost {
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    font-weight: bold;
    font-size: 40px;
    margin: 0;
    &::after {
      font-size: 16px;
      content: 'R';
      position: relative;
      top: -16px;
      right: -1px;
    }
  }
  p {
    position: relative;
    top: -5px;
    font-size: 14px;
    margin: 0;
  }
}

.switch-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0 0;
  padding: 0 4% 0 calc(4% - 10px);

  p {
    font-weight: bold;
    font-size: 16px;
    margin: 0;
  }
}

.bta-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  padding: 0 5%;
}

.bonus__online {
  margin-bottom: 20px;
  width: 90%;
  border-radius: 15px;
  background-color: #e4e4e480;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 18px 15px 10px;
  h4 {
    font-size: 16px;
    margin: 0;
  }
}

// modifier
.credit-buttons {
  flex-direction: column;
  margin-bottom: 30px;
}

.button__text.active--detail {
  font-size: 18px;
  color: #c69d4e;
}

.button--form {
  margin-bottom: 30px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
}
</style>
