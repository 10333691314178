<template>
  <div :class="['cmp-wrapper', isDark && 'cmp-wrapper--dark']">
    <navbar :msg="title" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading' && firstLoad"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <failed-mode v-else-if="pageStatus == 'failed'" @refresh="intersected" />
    <section class="load-resolved" v-else>
      <div class="cmp-items-wrapper">
        <offer-customer
          v-for="customer in bonusItems"
          :key="customer['wallet']['id']"
          :status="status"
          :name="customer['wallet']['name']"
          :image="customer['wallet']['image']"
          @click="() => enterCustomer(customer['wallet']['id'])"
        />
      </div>
      <loader v-show="pageStatus == 'loading' && !firstLoad" />
      <observer @intersect="intersected" />
    </section>
    <fixed-footer />
  </div>
</template>

<script>
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import OfferCustomer from '@view/store/offer/subComponents/OfferCustomer'

export default {
  name: 'BonusStatus',
  props: ['id', 'status'],
  components: {
    OfferCustomer
  },
  setup(props) {
    const isDark = computed(() => props.status !== '1')
    const title = computed(() => {
      switch (props.status) {
        case '1':
          return 'فعال'

        case '2':
          return 'مصرف شده'

        case '3':
          return 'باطل شده'

        default:
          return null
      }
    })
    const pageStatus = computed(() => store.state.bonus.pageStatus)

    // API call
    const store = useStore()
    const bonusItems = computed(() => store.state.bonus.backupItems)
    store.dispatch('bonus/fetchItems', {
      part: 'bonusItem',
      backup: true,
      query: { bonus: props.id, status: props.status }
    })

    // Implementing intersection
    const intersected = () => {
      if (pageStatus.value == 'loading') return
      store.dispatch('bonus/fetchItems', {
        part: 'bonusItem',
        backup: true,
        query: { bonus: props.id, status: props.status }
      })
    }

    // Handle two loadings
    const firstLoad = ref(true)
    watch(pageStatus, (curr) => {
      if (curr == 'resolved' || curr == 'failed') firstLoad.value = false
    })

    // Enter customer
    const router = useRouter()
    const enterCustomer = (id) => {
      router.push({ name: 'CustomerShow', params: { id } })
    }

    // Clean the room
    onBeforeUnmount(() => store.dispatch('bonus/clearBackupItems'))

    return {
      isDark,
      title,
      intersected,
      enterCustomer,
      pageStatus,
      bonusItems,
      firstLoad
    }
  }
}
</script>

<style lang="scss" scoped>
.cmp-wrapper {
  padding-bottom: 75px;
}
.load-pending--show {
  height: calc(100vh - 135px);
}
</style>
