<template>
  <section class="list">
    <bonus-single
      v-for="bonus in bonuses"
      :key="bonus.id"
      :price="bonus['price']"
      :min-buy="bonus['min_buy']"
      :is-dark="bonus['status'] !== '1'"
      @click="() => enterBonus(bonus.id)"
    />
  </section>
  <empty-part
    desc-top="بونوسی"
    desc-bottom="بونوس"
    v-if="pageStatus == 'resolved' && bonuses.length === 0"
  />
  <failed-mode @refresh="intersected" v-if="pageStatus == 'failed'" />
  <loader v-if="pageStatus == 'loading'" />
  <observer @intersect="intersected" />
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { computed, onBeforeUnmount } from 'vue'
import BonusSingle from './subComponents/BonusSingle'
import EmptyPart from '@common/EmptyPart'

export default {
  components: {
    BonusSingle,
    EmptyPart
  },
  setup() {
    // Fetch Bonuses
    const store = useStore()
    store.dispatch('bonus/fetchItems', { part: 'bonus' })
    const bonuses = computed(() => store.state.bonus.items)
    const pageStatus = computed(() => store.state.bonus.pageStatus)

    // Implementing intersection
    const intersected = () => {
      if (pageStatus.value == 'loading') return
      store.dispatch('bonus/fetchItems', { part: 'bonus' })
    }

    // Routing for entering offers
    const router = useRouter()
    const enterBonus = (id) => {
      router.push({ name: 'BonusShow', params: { id } })
    }

    // Clear offers
    onBeforeUnmount(() => store.dispatch('bonus/clearItems'))

    // ------- //
    return { bonuses, intersected, enterBonus, pageStatus }
  }
}
</script>
